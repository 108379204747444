import * as translationEn from './en.json';
import * as translationNl from './nl.json';

const TRANSLATIONS = {
  en: translationEn,
  nl: translationNl,
};

let currentLang = 'nl';

const getCurrentLang = () => {
  const windowHref = window.location.href;
  const windowHrefSplit = windowHref.split('/').reverse();
  if (windowHrefSplit[0] && windowHrefSplit[0] === 'nl' || windowHrefSplit[0] === 'en') {
    currentLang = windowHrefSplit[0];
  }
};

export function t(label) {
  getCurrentLang();
  return (TRANSLATIONS[currentLang].default[label]) ? TRANSLATIONS[currentLang].default[label] : 'NO_TRANSLATION_SET';
}
