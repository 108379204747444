import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import {t} from '../../i18n/i18n';

export class ChartConfig {

  static get() {

    return {
      plugins: {
        scene: [{
          key: 'rexUI',
          plugin: UIPlugin,
          mapping: 'rexUI'
        },
        ]
      },
      type: 'bar',
      data: {
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
        datasets: [{
          label: 'Data',
          backgroundColor: '118,166,171,1',
          borderColor: '118,166,171,1',
          borderWidth: 1,
          data: []
        }]
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'Data'
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: t('ponzo_illusion_graph_y_label')
            },
            ticks: {
              beginAtZero: true
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: t('ponzo_illusion_graph_x_label')
            },
          }]
        }
      }
    };
  }
}




