import Phaser from 'phaser';
import {screenState} from './enums';
import {Result} from './interfaces';
import * as _ from 'lodash';
import {t} from '../../i18n/i18n';
import {Chart} from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import {ChartConfig} from './chart-config';
import {ExportToCsv} from 'export-to-csv';
import {BaseScene} from '../base-scene/base-scene';

export class PonzoIllusionScene extends BaseScene {

  // Objects on screen
  footerRectangle;
  lineAmount; // 11 , 9, 7, 5, 3, 1, 0
  lineGraphics;
  lineSize;
  gamesToPlay = 10;

  tutorialTitle;
  tutorialInstructions;
  endedInstructions;
  feedbackText;

  chartStat;
  tableStat;

  // Buttons
  spacebar;
  next;
  refresh;
  check;
  longer;
  shorter;
  more;
  less;
  nextIcon;
  lessIcon;
  moreIcon;
  shorterIcon;
  longerIcon;
  checkIcon;
  refreshIcon;

  // current selection
  currentReferenceX1: number;
  currentReferenceX2: number;
  currentReferenceWidth: number;
  currentReferenceHeight: number;
  currentDistance: number;

  tutorialPlayed = false;

  results: Result[] = [];
  gameState: string = screenState.WAITING;

  constructor() {
    super({active: false, visible: false, key: 'Game'});

    this.setBaseBar();
    this.lineSize = 200;
    this.lineAmount = 7;
  }

  create() {

    this.spacebar = this.input.keyboard.addKey(32);
    this.spacebar.on('up', (event) => {
      this.continue();
    });
    this.getNextScreen();
  }

  continue() {
    if (this.gameState === screenState.WAITING) {
      this.gameState = screenState.PLAYING;
      this.tutorialTitle.destroy();
      this.tutorialInstructions.destroy();
      this.getNextScreen();
    }

    if (this.gameState === screenState.TUTORIAL_ENDED) {
      this.gameState = screenState.PLAYING;
      this.waitForNextScreen();
    }
  }

  displayScreen(): void {
    switch (this.gameState) {
      case screenState.WAITING:
        this.waitingScreen();
        break;
      case screenState.PLAYING:
        this.playingScreen();
        break;
      case screenState.TUTORIAL_ENDED:
        this.tutorialEnded();
        break;
      case screenState.GRAPH:
        this.graph();
        break;
      case screenState.TABLE:
        this.table();
        break;
      case screenState.EXPORT:
        this.exportCsv();
        break;
    }
  }

  waitingScreen(): void {
    this.tutorialTitle = this.add.text(120, 80, t('ponzo_illusion_tutorial_title'), {
      fill: '#000',
      fontSize: this.defaultHeaderFontSize
    }) as any;
    this.tutorialInstructions = this.add.text(120, 110, t('ponzo_illusion_tutorial_instructions'), {
      color: '#000',
      fontSize: this.defaultFontSize,
      wordWrap: {
        width: 560,
        useAdvancedWrap: false
      }
    }) as any;
  }

  getNextScreen(): void {
    this.destroyElements();
    this.displayScreen();
  }

  playingScreen() {
    this.createPlayingFooterMenu();
    if (this.lineGraphics) {
      this.lineGraphics.clear();
    }

    this.lineGraphics = this.add.graphics({lineStyle: {width: 2, color: 0x000}});

    const linesToPrint = [];

    if (this.lineAmount >= 1) {
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 400, 500));
    }

    if (this.lineAmount >= 3) {
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 150, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 650, 500));
    }

    if (this.lineAmount === 5) {
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 275, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 525, 500));
    }

    if (this.lineAmount === 7) {
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 233, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 316, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 484, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 567, 500));
    }

    if (this.lineAmount === 9) {
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 212, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 274, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 336, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 464, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 526, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 588, 500));
    }

    if (this.lineAmount === 11) {
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 200, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 250, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 300, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 350, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 450, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 500, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 550, 500));
      linesToPrint.push(new Phaser.Geom.Line(400, 100, 600, 500));
    }

    _.forEach(linesToPrint, (line) => {
      this.lineGraphics.strokeLineShape(line);
    });

    const lineHorizontal1 = new Phaser.Geom.Line(this.currentReferenceX1, this.currentReferenceHeight,
      this.currentReferenceX2, this.currentReferenceHeight);

    this.lineGraphics.strokeLineShape(lineHorizontal1);

    const hor2X1 = (800 / 2) - (this.lineSize / 2);
    const hor2X2 = (800 / 2) + (this.lineSize / 2);

    const lineHorizontal2 = new Phaser.Geom.Line(hor2X1, this.currentDistance, hor2X2, this.currentDistance);
    this.lineGraphics.strokeLineShape(lineHorizontal2);
  }

  setBaseBar() {
    const randomWidth = this.getRandomNumberBetween(20, 60) * 5;
    const randomHeight = this.getRandomNumberBetween(250, 400);

    this.currentReferenceWidth = randomWidth;
    this.currentReferenceX1 = (800 / 2) - (randomWidth / 2);
    this.currentReferenceX2 = (800 / 2) + (randomWidth / 2);

    this.currentReferenceHeight = randomHeight;

    const randomDistance = this.getRandomNumberBetween(50, 100);
    this.currentDistance = randomHeight + randomDistance;
  }

  refreshGame() {
    this.lineSize = 200;
    this.lineAmount = 7;
    this.setBaseBar();
    this.getNextScreen();
  }

  nextGame() {
    this.destroyFeedbackText();

    this.gameState = screenState.TUTORIAL_ENDED;
    this.tutorialPlayed = true;
    this.lineSize = 200;
    this.setBaseBar();
    this.getNextScreen();
  }

  checkGame() {
    this.destroyFeedbackText();

    const percentage = this.roundDecimals(100 - (this.lineSize / (this.currentReferenceWidth / 100)));

    if (this.lineSize !== this.currentReferenceWidth && !this.tutorialPlayed) {
      let textToShow = '';
      if (percentage < 0) {
        textToShow = (percentage * -1) + '% ' + t('too_long');
      } else {
        textToShow = percentage + '% ' + t('too_short');
      }
      this.feedbackText = this.add.text(335, 50, textToShow, {color: '#000'}) as any;


      return this.getNextScreen();
    }

    if (this.lineSize === this.currentReferenceWidth && !this.tutorialPlayed) {
      this.feedbackText = this.add.text(375, 50, t('correct'), {color: '#000'}) as any;
      return this.getNextScreen();
    }

    if (this.tutorialPlayed) {
      this.results.push({
        divergent: percentage,
        lengthReference: this.currentReferenceWidth,
        lengthOwn: this.lineSize
      });

      if (this.results.length === this.gamesToPlay) {
        this.gameState = screenState.GRAPH;
        this.createStatsFooterMenu();
      } else {
        this.lineSize = 200;
        this.lineAmount = 7;
        this.setBaseBar();
      }
    }

    return this.getNextScreen();
  }

  alterLineLength(alter: number) {
    this.destroyFeedbackText();
    if (this.lineSize === 0 && alter < 0) {
      return this.getNextScreen();
    }
    if (this.lineSize === 600 && alter > 0) {
      return this.getNextScreen();
    }
    this.lineSize = this.lineSize + alter;

    return this.getNextScreen();
  }

  lessLines() {
    this.destroyFeedbackText();

    let newLines = this.lineAmount - 2;
    if (newLines < 1) {
      newLines = 0;
    }
    this.lineAmount = newLines;
    this.getNextScreen();
  }

  moreLines() {
    this.destroyFeedbackText();
    let newLines = 0;
    if (this.lineAmount === 0) {
      newLines = 1;
    } else {
      newLines = this.lineAmount + 2;
    }

    if (newLines > 11) {
      newLines = 11;
    }
    this.lineAmount = newLines;
    this.getNextScreen();
  }

  tutorialEnded() {
    this.endedInstructions = this.add.text(120, 100, t('ponzo_illusion_ended_instructions'), {
      color: '#000',
      fontSize: this.defaultFontSize,
      wordWrap: {
        width: 560,
        useAdvancedWrap: false
      }
    }) as any;
  }

  exportCsv() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: false,
      filename: t('ponzo_illusion_export'),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
    };

    const data = [];
    let count = 1;
    data.push({
      attempt: t('attempt'),
      divergent: t('divergent'),
      lengthReference: t('bar_reference'),
      lengthOwn: t('bar_own')
    });

    _.forEach(this.results, (result: Result) => {
      data.push({
        attempt: count,
        divergent: result.divergent,
        lengthReference: result.lengthReference,
        lengthOwn: result.lengthOwn,
      });
      count++;
    });
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  graph() {
    this.chartStat = new Chart(this, 400, 250, 400, 400, ChartConfig.get());

    _.forEach(this.results, (result, key) => {
      this.chartStat.setChartData(0, key, result.divergent).updateChart();
    });
    this.add.existing(this.chartStat);
  }

  table() {
    let html = '';
    html += '<div style="overflow-y:auto; height: 400pt;"><table style="width: 500pt">';
    html += '<thead>' +
      '<th style="text-align: left;">' + t('attempt') + '</th>' +
      '<th style="text-align: left;">' + t('divergent') + '</th>' +
      '<th style="text-align: left;">' + t('bar_reference') + '</th>' +
      '<th style="text-align: left;">' + t('bar_own') + '</th>' +
      '</thead>';


    let count = 1;
    _.forEach(this.results, (result: Result) => {
      html += '<tr>';

      html += '<td>' + count + '</td>';
      html += '<td>' + result.divergent + '</td>';
      html += '<td>' + result.lengthReference + '</td>';
      html += '<td>' + result.lengthOwn + '</td>';
      html += '</tr>';
      count++;
    });

    html += '</table></div>';

    this.tableStat = this.add.dom(360, 400).createFromHTML(html);
  }

  destroyFeedbackText() {
    if (this.feedbackText) {
      this.feedbackText.destroy();
    }
  }

  destroyElements() {
    if (this.chartStat) {
      this.chartStat.destroy();
    }
    if (this.tableStat) {
      this.tableStat.destroy();
    }
    if (this.endedInstructions) {
      this.endedInstructions.destroy();
    }
    if (this.footerRectangle) {
      this.footerRectangle.destroy();
    }
    if (this.next) {
      this.next.destroy();
    }
    if (this.refresh) {
      this.refresh.destroy();
      this.refreshIcon.destroy();
    }
    if (this.check) {
      this.check.destroy();
      this.checkIcon.destroy();
    }
    if (this.longer) {
      this.longer.destroy();
      this.longerIcon.destroy();
    }
    if (this.shorter) {
      this.shorter.destroy();
      this.shorterIcon.destroy();
    }
    if (this.more) {
      this.more.destroy();
      this.moreIcon.destroy();
    }
    if (this.less) {
      this.less.destroy();
      this.lessIcon.destroy();
    }
    if (this.next) {
      this.next.destroy();
      this.nextIcon.destroy();
    }
    if (this.lineGraphics) {
      this.lineGraphics.clear();
    }
  }

  waitForNextScreen() {
    setTimeout(() => {
      this.emptyScreen();
    }, 1000);
  }

  emptyScreen() {
    this.destroyElements();

    setTimeout(() => {
      this.getNextScreen();
    }, 500);
  }

  createStatsFooterMenu() {
    this.add.text(265, 520, t('download'), {fill: '#000000', size: '10'});
    this.add.image(295, 572, 'download').setInteractive().on('pointerup', () => {
      this.gameState = screenState.EXPORT;
      this.getNextScreen();
    });

    this.add.text(365, 520, t('graph'), {fill: '#000000', size: '10'});
    this.add.image(395, 572, 'graph').setInteractive().on('pointerup', () => {
      this.gameState = screenState.GRAPH;
      this.getNextScreen();
    });

    this.add.text(465, 520, t('table'), {fill: '#000000', size: '10'});
    this.add.image(495, 572, 'table').setInteractive().on('pointerup', () => {
      this.gameState = screenState.TABLE;
      this.getNextScreen();
    });
  }

  createPlayingFooterMenu() {
    this.footerRectangle = this.add.rectangle(400, 528, 800, 20, 0xb4b4b4);

    this.shorter = this.add.text(230, 520, t('shorter'), {fill: '#000000', size: '10'});
    this.shorterIcon = this.add.image(260, 570, 'shorter').setInteractive().on('pointerup', () => {
      this.alterLineLength(-5);
    });

    this.longer = this.add.text(320, 520, t('longer'), {fill: '#000000', size: '10'});
    this.longerIcon = this.add.image(345, 570, 'longer').setInteractive().on('pointerup', () => {
      this.alterLineLength(5);
    });

    this.check = this.add.text(450, 520, t('check'), {fill: '#000000', size: '10', align: 'center'});
    this.checkIcon = this.add.image(500, 570, 'check').setInteractive().on('pointerup', () => {
      this.checkGame();
    });

    if (!this.tutorialPlayed) {
      this.refresh = this.add.text(590, 520, t('refresh'), {fill: '#000000', size: '10'});
      this.refreshIcon = this.add.image(625, 570, 'refresh').setInteractive().on('pointerup', () => {
        this.refreshGame();
      });

      this.next = this.add.text(690, 520, t('next'), {fill: '#000000', size: '10'});
      this.nextIcon = this.add.image(730, 570, 'next').setInteractive().on('pointerup', () => {
        this.nextGame();
      });

      this.less = this.add.text(50, 520, t('less'), {fill: '#000000', size: '10'});
      this.lessIcon = this.add.image(68, 570, 'less').setInteractive().on('pointerup', () => {
        this.lessLines();
      });

      this.more = this.add.text(120, 520, t('more'), {fill: '#000000', size: '10'});
      this.moreIcon = this.add.image(138, 570, 'more').setInteractive().on('pointerup', () => {
        this.moreLines();
      });
    }
  }
}
