import {Component, OnDestroy, OnInit} from '@angular/core';
import Phaser from 'phaser';
import {gameConfig} from './game-config';

@Component({
  selector: 'app-visual-search-base',
  templateUrl: './visual-search-base.component.html',
  styleUrls: ['./visual-search-base.component.scss']
})

export class VisualSearchBaseComponent implements OnInit, OnDestroy {

  phaserGame: Phaser.Game;
  config: Phaser.Types.Core.GameConfig;

  constructor() {

  }

  ngOnDestroy() {
    this.phaserGame.destroy(true);
  }

  ngOnInit() {
    this.phaserGame = new Phaser.Game(gameConfig);
  }
}
