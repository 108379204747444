import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visual-search',
  templateUrl: './visual-search.component.html',
  styleUrls: ['./visual-search.component.scss']
})
export class VisualSearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {


  }

}
