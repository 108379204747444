import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simon-effect',
  templateUrl: './simon-effect.component.html',
  styleUrls: ['./simon-effect.component.scss']
})
export class SimonEffectComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }

}
