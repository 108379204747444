import {Component, OnDestroy, OnInit} from '@angular/core';
import Phaser from 'phaser';
import {gameConfig} from './game-config';

@Component({
  selector: 'app-simon-effect-base',
  templateUrl: './simon-effect-base.component.html',
  styleUrls: ['./simon-effect-base.component.scss']
})
export class SimonEffectBaseComponent implements OnInit, OnDestroy {

  phaserGame: Phaser.Game;
  config: Phaser.Types.Core.GameConfig;

  constructor() {

  }

  ngOnDestroy() {
    this.phaserGame.destroy(true);
  }

  ngOnInit() {
    this.phaserGame = new Phaser.Game(gameConfig);
  }

}
