import Phaser from 'phaser';
import {Chart} from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import {ChartConfig} from './chart-config';
import {ExportToCsv} from 'export-to-csv';
import {screenState, stimulus, tiles, types} from './enums';
import {Result, Statistics} from './interfaces';
import * as _ from 'lodash';
import {t} from '../../i18n/i18n';
import {BaseScene} from '../base-scene/base-scene';

export class VisualSearchScene extends BaseScene {

  // Objects on screen
  squares = [];
  tutorialTitle;
  tutorialInstructions;
  text;

  graphAbsentLeft;
  graphPresentLeft;
  graphPresentRight;
  graphAbsentRight;
  graphFeatureSearch;
  graphConjunctiveSearch;
  chartStat;
  rawStat;
  tableStat;

  // Buttons
  present;
  absent;
  spacebar;

  // timer
  timer;

  // Cluster
  clusterFour: number;
  clusterSixteen: number;
  clusterSixtyfour: number;
  clusterPresent: number;
  clusterAbsent: number;

  clusterGames = 2;

  // current selection
  currentTiles: string;
  currentStimulus: string;
  currentType: string;

  tutorialPlayed = false;

  results: Result[] = [];
  statistics: Statistics;

  inputGiven = false;
  gameState: string = screenState.WAITING;

  squareSize = 25;

  constructor() {
    super({active: false, visible: false, key: 'Game'});

    this.clusterFour = 1;
    this.clusterSixteen = 2;
    this.clusterSixtyfour = 2;
    this.clusterPresent = 2;
    this.clusterAbsent = 3;

    const randomNumberType = this.getRandomNumberBetween(1, 2);

    if (randomNumberType === 1) {
      this.currentType = types.FEATURE;
    }
    if (randomNumberType === 2) {
      this.currentType = types.CONJUNCTIVE;
    }
  }

  create() {
    this.present = this.input.keyboard.addKey(77).on('up', (event) => { // m
      this.press(stimulus.PRESENT);
    });
    this.absent = this.input.keyboard.addKey(90).on('up', (event) => { // z
      this.press(stimulus.ABSENT);
    });
    this.spacebar = this.input.keyboard.addKey(32).on('up', (event) => {
      this.continue();
    });

    this.getNextScreen();
  }

  displayScreen(): void {

    switch (this.gameState) {
      case screenState.WAITING:
        this.waitingScreen();
        break;
      case screenState.PLAYING:
        this.playingScreen();
        break;
      case screenState.TUTORIAL_ENDED:
        this.tutorialEnded();
        break;
      case screenState.ONHOLD:
        this.onHold();
        break;
      case screenState.NEXT_GAME:
        this.nextGame();
        break;
      case screenState.GRAPH:
        this.graph();
        break;
      case screenState.TABLE:
        this.table();
        break;
      case screenState.RAWDATA:
        this.rawData();
        break;
      case screenState.EXPORT:
        this.exportCsv();
        break;
    }
  }

  createEndFooterMenu() {
    this.add.rectangle(400, 528, 800, 20, 0xb4b4b4);

    this.add.text(215, 520, t('download'), {fill: '#000000'});
    this.add.image(245, 572, 'download').setInteractive().on('pointerup', () => {
      this.gameState = screenState.EXPORT;
      this.getNextScreen();
    });

    this.add.text(315, 520, t('graph'), {fill: '#000000'});
    this.add.image(345, 572, 'graph').setInteractive().on('pointerup', () => {
      this.gameState = screenState.GRAPH;
      this.getNextScreen();
    });

    this.add.text(415, 520, t('table'), {fill: '#000000'});
    this.add.image(445, 572, 'table').setInteractive().on('pointerup', () => {
      this.gameState = screenState.TABLE;
      this.getNextScreen();
    });

    this.add.text(515, 520, t('raw'), {fill: '#000000'});
    this.add.image(545, 572, 'rawdata').setInteractive().on('pointerup', () => {
      this.gameState = screenState.RAWDATA;
      this.getNextScreen();
    });
  }

  getNextScreen(): void {
    this.destroyElements();

    // Tutorial finished
    if (this.clusterFour === 0 && this.clusterSixteen === 0 && this.clusterSixtyfour === 0 &&
      this.gameState === screenState.PLAYING && this.tutorialPlayed === false && this.currentType === types.FEATURE) {

      // Regular game set
      this.clusterFour = 8;
      this.clusterSixteen = 8;
      this.clusterSixtyfour = 8;
      this.clusterPresent = 12;
      this.clusterAbsent = 12;
      this.tutorialPlayed = true;
      this.gameState = screenState.TUTORIAL_ENDED;
      return this.displayScreen();
    }

    // Game finished
    if (this.clusterFour === 0 && this.clusterSixteen === 0 && this.clusterSixtyfour === 0 &&
      this.gameState === screenState.PLAYING && this.tutorialPlayed === true && this.currentType === types.FEATURE) {

      // Tutorial game set
      this.clusterFour = 1;
      this.clusterSixteen = 2;
      this.clusterSixtyfour = 2;
      this.clusterPresent = 2;
      this.clusterAbsent = 3;
      this.currentType = types.CONJUNCTIVE;
      this.tutorialPlayed = false;
      this.gameState = screenState.NEXT_GAME;
      this.clusterGames = this.clusterGames - 1;
    }

    // Tutorial finished
    if (this.clusterFour === 0 && this.clusterSixteen === 0 && this.clusterSixtyfour === 0 &&
      this.gameState === screenState.PLAYING && this.tutorialPlayed === false && this.currentType === types.CONJUNCTIVE) {

      // Regular game set
      this.clusterFour = 8;
      this.clusterSixteen = 8;
      this.clusterSixtyfour = 8;
      this.clusterPresent = 12;
      this.clusterAbsent = 12;
      this.tutorialPlayed = true;
      this.gameState = screenState.TUTORIAL_ENDED;
      return this.displayScreen();
    }

    // Game finished
    if (this.clusterFour === 0 && this.clusterSixteen === 0 && this.clusterSixtyfour === 0 &&
      this.gameState === screenState.PLAYING && this.tutorialPlayed === true && this.currentType === types.CONJUNCTIVE) {

      // Tutorial game set
      this.clusterFour = 1;
      this.clusterSixteen = 2;
      this.clusterSixtyfour = 2;
      this.clusterPresent = 2;
      this.clusterAbsent = 3;
      this.currentType = types.FEATURE;
      this.tutorialPlayed = false;
      this.gameState = screenState.NEXT_GAME;
      this.clusterGames = this.clusterGames - 1;
    }

    if (this.gameState === screenState.NEXT_GAME && this.clusterGames === 0) {

      this.gameState = screenState.GRAPH;
      this.calculateStatistics();
      this.createEndFooterMenu();
    }

    this.displayScreen();
  }

  waitingScreen(): void {
    this.tutorialTitle = this.add.text(120, 80, t('visual_search_tutorial_title'), {
      fill: '#FFFFFF',
      fontSize: this.defaultHeaderFontSize
    }) as any;
    this.tutorialInstructions = this.add.text(120, 110, t('visual_search_tutorial_instructions'), {
      color: '#FFFFFF',
      fontSize: this.defaultFontSize,
      wordWrap: {
        width: 560,
        useAdvancedWrap: false
      }
    }) as any;
  }

  tutorialEnded() {
    // checken welke tutorial dit is
    if (this.currentType === types.FEATURE) {
      this.text = this.add.text(120, 100, t('visual_search_tutorial_feature_ended'), {
        color: '#FFFFFF',
        fontSize: this.defaultFontSize,
      }) as any;
    }
    if (this.currentType === types.CONJUNCTIVE) {
      this.text = this.add.text(120, 100, t('visual_search_tutorial_conjunctive_ended'), {
        color: '#FFFFFF',
        fontSize: this.defaultFontSize,
      }) as any;
    }
  }

  playingScreen() {
    const sumOfTiles = this.clusterFour + this.clusterSixteen + this.clusterSixtyfour;
    const sumOfDots = this.clusterPresent + this.clusterAbsent;

    const randomNumberTiles = this.getRandomNumberBetween(1, sumOfTiles); // 6 => 1,2 | 3,4| 5,6|
    const randomNumberGreenDot = this.getRandomNumberBetween(1, sumOfDots); // 3

    let tilesAmount = null;
    let showStimulus = null;

    // number of tiles
    if (randomNumberTiles <= this.clusterFour) {
      this.currentTiles = tiles.FOUR;
      tilesAmount = 4;
    }

    if (randomNumberTiles > this.clusterFour && randomNumberTiles <= (this.clusterFour + this.clusterSixteen)) {
      this.currentTiles = tiles.SIXTEEN;
      tilesAmount = 16;
    }

    if (randomNumberTiles > (this.clusterFour + this.clusterSixteen)) {
      this.currentTiles = tiles.SIXTYFOUR;
      tilesAmount = 64;
    }

    // green dot
    if (randomNumberGreenDot <= this.clusterPresent) {
      this.currentStimulus = stimulus.PRESENT;
      showStimulus = true;
    }

    if (randomNumberGreenDot > this.clusterPresent) {
      this.currentStimulus = stimulus.ABSENT;
      showStimulus = false;
    }

    if (this.currentType === types.FEATURE) {
      this.featureSearch(tilesAmount, showStimulus);
    }
    if (this.currentType === types.CONJUNCTIVE) {
      this.conjectiveSearch(tilesAmount, showStimulus);
    }

    this.timer = this.time.addEvent({
      loop: true
    });
    this.inputGiven = false;
  }

  getCoordinates() {
    const x = this.getRandomNumberBetween(50, 750);
    const y = this.getRandomNumberBetween(50, 550);
    const width = 50;

    let overlap = false;
    _.forEach(this.squares, (square) => {
      if (Math.abs(square.x - x) < width && Math.abs(square.y - y) < width) {
        overlap = true;
        return false;
      }
    });

    if (overlap) {
      return this.getCoordinates();
    }

    return {x, y};
  }

  featureSearch(tilesAmount, showStimulus) {
    let placedDot = false;
    for (let i = 1; i <= tilesAmount; i++) {

      const coordinates = this.getCoordinates();

      if (showStimulus && !placedDot) {
        this.squares.push(this.add.circle(coordinates.x, coordinates.y, (this.squareSize / 2), 0x1AC426) as any);
        placedDot = true;
        continue;
      }

      this.squares.push(this.add.rectangle(coordinates.x, coordinates.y, this.squareSize, this.squareSize, 0xFC4F07) as any);
    }
  }

  conjectiveSearch(tilesAmount, showStimulus) {
    let placedDot = false;
    const percentageGreen = 0.5;
    let greenTiles = tilesAmount * percentageGreen;

    for (let i = 1; i <= tilesAmount; i++) {
      const coordinates = this.getCoordinates();
      if (showStimulus && !placedDot) {
        this.squares.push(this.add.circle(coordinates.x, coordinates.y, (this.squareSize / 2), 0x1AC426) as any);
        placedDot = true;
        continue;
      }

      if (greenTiles !== 0) {
        this.squares.push(this.add.rectangle(coordinates.x, coordinates.y, this.squareSize, this.squareSize, 0x1AC426) as any);
        greenTiles--;
      } else {
        this.squares.push(this.add.circle(coordinates.x, coordinates.y, (this.squareSize / 2), 0xFC4F07) as any);
      }
    }
  }

  onHold() {
    this.text = this.add.text(150, 300, t('timeout'), {color: '#FFFFFF'}) as any;
  }

  nextGame() {
    this.text = this.add.text(120, 100, t('visual_search_next_game'), {
      color: '#FFFFFF',
      fontSize: this.defaultFontSize,
      wordWrap: {
        width: 560,
        useAdvancedWrap: false
      }
    }) as any;
  }

  continue() {
    if (this.gameState === screenState.WAITING) {
      this.gameState = screenState.PLAYING;
      this.tutorialTitle.destroy();
      this.tutorialInstructions.destroy();
      this.getNextScreen();
    }

    if (this.gameState === screenState.ONHOLD || this.gameState === screenState.TUTORIAL_ENDED ||
      this.gameState === screenState.NEXT_GAME) {
      this.gameState = screenState.PLAYING;
      this.waitForNextScreen();
    }
  }

  exportCsv() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: false,
      filename: t('visual_search_export'),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
    };

    const data = [];
    let count = 1;

    data.push({
      nr: t('nr'),
      search: t('seach_type'),
      stimulus: t('stimulus'),
      amount: t('quantity'),
      time: t('reaction_time')
    });

    _.forEach(this.results, (result: Result) => {
      data.push({
        nr: count,
        search: result.type,
        stimulus: result.stimulus,
        amount: this.getNumberByName(result.tiles),
        time: result.time,
        correct: (result.correct) ? t('correct_input') : t('incorrect_input')
      });
      count++;
    });
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  graph() {
    this.cameras.main.backgroundColor = Phaser.Display.Color.HexStringToColor('#FFFFFF');

    this.chartStat = new Chart(this, 400, 200, 600, 400, ChartConfig.get());

    this.chartStat.setChartData(0, 0, this.statistics.feature.absent.four);
    this.chartStat.setChartData(0, 1, this.statistics.feature.absent.sixteen);
    this.chartStat.setChartData(0, 2, this.statistics.feature.absent.sixtyfour);
    this.chartStat.setChartData(0, 3, this.statistics.feature.present.four);
    this.chartStat.setChartData(0, 4, this.statistics.feature.present.sixteen);
    this.chartStat.setChartData(0, 5, this.statistics.feature.present.sixtyfour);

    this.chartStat.setChartData(0, 6, this.statistics.conjunctive.absent.four);
    this.chartStat.setChartData(0, 7, this.statistics.conjunctive.absent.sixteen);
    this.chartStat.setChartData(0, 8, this.statistics.conjunctive.absent.sixtyfour);
    this.chartStat.setChartData(0, 9, this.statistics.conjunctive.present.four);
    this.chartStat.setChartData(0, 10, this.statistics.conjunctive.present.sixteen);
    this.chartStat.setChartData(0, 11, this.statistics.conjunctive.present.sixtyfour);

    this.chartStat.updateChart();
    this.add.existing(this.chartStat);

    const style = {color: '#000000', fontFamily: 'Helvetica', fontSize: '12px'};
    this.graphAbsentLeft = this.add.text(180, 410, t('absent'), style) as any;
    this.graphPresentLeft = this.add.text(320, 410, t('present'), style) as any;
    this.graphAbsentRight = this.add.text(460, 410, t('absent'), style) as any;
    this.graphPresentRight = this.add.text(580, 410, t('present'), style) as any;
    this.graphFeatureSearch = this.add.text(220, 450, 'Feature search', style) as any;
    this.graphConjunctiveSearch = this.add.text(480, 450, 'Conjunctive search', style) as any;
  }

  table() {

    let html = '';
    html += '<div style="overflow-y:auto; height: 400pt;"><table style="width: 500pt">';
    html += '<thead>' +
      '<th style="text-align: left;">' + t('seach_type') + '</th>' +
      '<th style="text-align: left;">' + t('stimulus') + '</th>' +
      '<th style="text-align: left;">' + t('quantity') + '</th>' +
      '<th style="text-align: left;">' + t('reaction_time') + '</th>' +
      '</thead>';


    html += this.tableHtmlRow(types.FEATURE, stimulus.ABSENT, tiles.FOUR);
    html += this.tableHtmlRow(types.FEATURE, stimulus.ABSENT, tiles.SIXTEEN);
    html += this.tableHtmlRow(types.FEATURE, stimulus.ABSENT, tiles.SIXTYFOUR);
    html += this.tableHtmlRow(types.FEATURE, stimulus.PRESENT, tiles.FOUR);
    html += this.tableHtmlRow(types.FEATURE, stimulus.PRESENT, tiles.SIXTEEN);
    html += this.tableHtmlRow(types.FEATURE, stimulus.PRESENT, tiles.SIXTYFOUR);

    html += this.tableHtmlRow(types.CONJUNCTIVE, stimulus.ABSENT, tiles.FOUR);
    html += this.tableHtmlRow(types.CONJUNCTIVE, stimulus.ABSENT, tiles.SIXTEEN);
    html += this.tableHtmlRow(types.CONJUNCTIVE, stimulus.ABSENT, tiles.SIXTYFOUR);
    html += this.tableHtmlRow(types.CONJUNCTIVE, stimulus.PRESENT, tiles.FOUR);
    html += this.tableHtmlRow(types.CONJUNCTIVE, stimulus.PRESENT, tiles.SIXTEEN);
    html += this.tableHtmlRow(types.CONJUNCTIVE, stimulus.PRESENT, tiles.SIXTYFOUR);

    html += '</table></div>';

    this.tableStat = this.add.dom(500, 400).createFromHTML(html);
  }

  getNumberByName(numberText: string): number {
    switch (numberText) {
      case 'four' :
        return 4;
      case 'sixteen' :
        return 16;
      case 'sixtyfour' :
        return 64;
    }
  }

  tableHtmlRow(type, stimu, tile) {
    let html = '';

    html += '<tr>';
    html += '<td>' + type + '</td>';
    html += '<td>' + t(stimu) + '</td>';
    html += '<td>' + this.getNumberByName(tile) + '</td>';
    html += '<td>' + this.statistics[type][stimu][tile] + '</td>';
    html += '</tr>';

    return html;
  }

  rawData() {
    let html = '';
    html += '<div style="overflow-y:auto; height: 300pt;"><table style="width: 500pt">';
    html += '<thead>' +
      '<th style="text-align: left;">' + t('nr') + '</th>' +
      '<th style="text-align: left;">' + t('seach_type') + '</th>' +
      '<th style="text-align: left;">' + t('stimulus') + '</th>' +
      '<th style="text-align: left;">' + t('quantity') + '</th>' +
      '<th style="text-align: left;">' + t('reaction_time') + '</th>' +
      '<th style="text-align: left;">' + t('correct_header') + '</th>' +
      '</thead>';

    let count = 1;
    _.forEach(this.results, (result: Result) => {
      const correctIncorrect = (result.correct) ? t('correct_input') : t('incorrect_input');
      html += '<tr>';
      html += '<td>' + count + '</td>';
      html += '<td>' + result.type + '</td>';
      html += '<td>' + t(result.stimulus) + '</td>';
      html += '<td>' + this.getNumberByName(result.tiles) + '</td>';
      html += '<td>' + result.time + '</td>';
      html += '<td>' + correctIncorrect + '</td>';
      html += '</tr>';
      count++;
    });

    html += '</table></div>';
    this.rawStat = this.add.dom(450, 250).createFromHTML(html);
  }

  destroyElements() {
    if (this.text) {
      this.text.destroy();
    }
    if (this.chartStat) {
      this.graphAbsentLeft.destroy();
      this.graphPresentLeft.destroy();
      this.graphPresentRight.destroy();
      this.graphAbsentRight.destroy();
      this.graphFeatureSearch.destroy();
      this.graphConjunctiveSearch.destroy();
      this.chartStat.destroy();
    }
    if (this.rawStat) {
      this.rawStat.destroy();
    }
    if (this.tableStat) {
      this.tableStat.destroy();
    }
    _.forEach(this.squares, (square) => {
      square.destroy();
    });
    this.squares = [];
  }

  press(stimulusGiven: string) {
    if (this.inputGiven || this.gameState !== screenState.PLAYING) {
      return;
    }
    const elapsed = this.timer.getElapsed();

    if (elapsed > 5000 && this.tutorialPlayed) {
      // Answer took to long
      this.gameState = screenState.ONHOLD;
    } else if (this.currentStimulus !== stimulusGiven) {
      // Incorrect stimulus
      this.inputGiven = true;
      this.text = this.add.text(350, 300, t('incorrect'), {
        color: '#FFFFFF',
        backgroundColor: '#000',
      }) as any;

      if (this.tutorialPlayed) {
        // Playing real game
        this.addResults(elapsed, false);
      } else {
        // Playing tutorial
        this.processTrial(stimulusGiven);
      }
    } else {
      // Correct stimulus
      this.inputGiven = true;
      this.text = this.add.text(350, 300, t('correct'), {
        color: '#FFFFFF',
        backgroundColor: '#000',
      }) as any;

      if (this.tutorialPlayed) {
        // Playing real game
        this.processTrial(stimulusGiven);
        this.addResults(elapsed, true);
      } else {
        // Playing tutorial
        this.processTrial(stimulusGiven);
      }
    }

    return this.waitForNextScreen();
  }

  processTrial(stimulusGiven: string) {
    if (stimulusGiven === stimulus.PRESENT) {
      this.clusterPresent--;
    }
    if (stimulusGiven === stimulus.ABSENT) {
      this.clusterAbsent--;
    }

    switch (this.currentTiles) {
      case tiles.FOUR:
        this.clusterFour--;
        break;
      case tiles.SIXTEEN:
        this.clusterSixteen--;
        break;
      case tiles.SIXTYFOUR:
        this.clusterSixtyfour--;
        break;
    }
  }

  addResults(elapsed: number, isCorrect: boolean) {
    if (this.tutorialPlayed) {
      this.results.push({
        type: this.currentType,
        stimulus: this.currentStimulus,
        tiles: this.currentTiles,
        time: this.roundDecimals(elapsed),
        correct: isCorrect,
      });
    }
  }

  waitForNextScreen() {
    setTimeout(() => {
      this.emptyScreen();
    }, 1000);
  }

  emptyScreen() {
    this.destroyElements();

    setTimeout(() => {
      this.getNextScreen();
    }, 500);
  }

  calcAvg(stimu: string, tile: string, type: string) {
    const dataSet = _.filter(this.results, (result: Result) => {
      return result.stimulus === stimu && result.tiles === tile && result.type === type && result.correct === true;
    });
    return this.roundDecimals(_.meanBy(dataSet, (result) => result.time));
  }

  calculateStatistics() {
    this.statistics = {
      feature: {
        present: {
          four: this.calcAvg(stimulus.PRESENT, tiles.FOUR, types.FEATURE),
          sixteen: this.calcAvg(stimulus.PRESENT, tiles.SIXTEEN, types.FEATURE),
          sixtyfour: this.calcAvg(stimulus.PRESENT, tiles.SIXTYFOUR, types.FEATURE),
        },
        absent: {
          four: this.calcAvg(stimulus.ABSENT, tiles.FOUR, types.FEATURE),
          sixteen: this.calcAvg(stimulus.ABSENT, tiles.SIXTEEN, types.FEATURE),
          sixtyfour: this.calcAvg(stimulus.ABSENT, tiles.SIXTYFOUR, types.FEATURE),
        }
      },
      conjunctive: {
        present: {
          four: this.calcAvg(stimulus.PRESENT, tiles.FOUR, types.CONJUNCTIVE),
          sixteen: this.calcAvg(stimulus.PRESENT, tiles.SIXTEEN, types.CONJUNCTIVE),
          sixtyfour: this.calcAvg(stimulus.PRESENT, tiles.SIXTYFOUR, types.CONJUNCTIVE),
        },
        absent: {
          four: this.calcAvg(stimulus.ABSENT, tiles.FOUR, types.CONJUNCTIVE),
          sixteen: this.calcAvg(stimulus.ABSENT, tiles.SIXTEEN, types.CONJUNCTIVE),
          sixtyfour: this.calcAvg(stimulus.ABSENT, tiles.SIXTYFOUR, types.CONJUNCTIVE),
        }
      }
    };
  }
}
