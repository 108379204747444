import Phaser from 'phaser';
import {SimonEffectScene} from './simon-effect-scene';

export const gameConfig: Phaser.Types.Core.GameConfig = {
  title: 'Sample',

  type: Phaser.AUTO,

  scale: {
    width: 800,
    height: 600,
  },

  physics: {
    default: 'arcade',
    arcade: {
      debug: true,
    },
  },
  dom: {
    createContainer: true
  },
  scene:  SimonEffectScene,
  parent: 'game',
  backgroundColor: '#ffffff',
};
