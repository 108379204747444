import {Chart} from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import {ChartConfig} from './chart-config';
import * as _ from 'lodash';
import {ExportToCsv} from 'export-to-csv';
import {colors, screenState, sides} from './enums';
import {Result, Statistics} from './interfaces';
import {t} from '../../i18n/i18n';
import {BaseScene} from '../base-scene/base-scene';

export class SimonEffectScene extends BaseScene {

  // Objects on screen
  square;
  focusLine;
  text;
  chartStat;
  rawStat;
  tableStat;

  // Buttons
  blue;
  orange;
  spacebar;

  // Footer
  footerRectangle;
  footerTextStart;

  // Popups
  initialPopup;
  initialPopupTitle;
  initialPopupText;

  // timer
  timer;

  // Cluster
  clusterBlue: number;
  clusterOrange: number;
  clusterLeft: number;
  clusterRight: number;

  // current selection
  currentColor: string;
  currentSide: string;

  tutorialPlayed = false;

  results: Result[] = [];
  statistics: Statistics;

  inputGiven = false;
  gameState: string = screenState.WAITING;

  constructor() {
    super({active: false, visible: false, key: 'Game'});
    this.clusterBlue = 2;
    this.clusterOrange = 3;
    this.clusterLeft = 2;
    this.clusterRight = 3;
  }

  create() {
    this.createInitialPopup();
    this.blue = this.input.keyboard.addKey(77).on('up', (event) => { // m
      this.press(colors.BLUE);
    });
    this.orange = this.input.keyboard.addKey(90).on('up', (event) => { // z
      this.press(colors.ORANGE);
    });
    this.spacebar = this.input.keyboard.addKey(32).on('up', (event) => {
      this.continue();
    });

    this.getNextScreen();
  }

  createInitialPopup() {
    this.initialPopupTitle = this.add.text(120, 80, t('simon_effect_tutorial_title'), {
      fill: '#000000',
      fontSize: this.defaultHeaderFontSize
    }) as any;
    this.initialPopupText = this.add.text(120, 110, t('simon_effect_tutorial_instructions'), {
      fill: '#000000',
      fontSize: this.defaultFontSize,
      wordWrap: {
        width: 560,
        useAdvancedWrap: false
      }
    }) as any;
  }

  tutorialEnded() {
    this.text = this.add.text(205, 80, t('simon_tutorial_ended'), {
      fill: '#000000',
      fontSize: this.defaultFontSize,
    }) as any;
  }

  footerDefaults() {
    this.footerRectangle = this.add.rectangle(400, 528, 800, 20, 0xb4b4b4) as any;
  }

  removeGameFooterMenu() {
    this.footerTextStart.destroy();
    this.footerRectangle.destroy();
  }

  createEndFooterMenu() {
    this.footerDefaults();

    this.add.text(215, 520, t('download'), {fill: '#000000'});
    this.add.image(245, 572, 'download').setInteractive().on('pointerup', () => {
      this.gameState = screenState.EXPORT;
      this.getNextScreen();
    });

    this.add.text(315, 520, t('graph'), {fill: '#000000'});
    this.add.image(345, 572, 'graph').setInteractive().on('pointerup', () => {
      this.gameState = screenState.GRAPH;
      this.getNextScreen();
    });

    this.add.text(415, 520, t('table'), {fill: '#000000'});
    this.add.image(445, 572, 'table').setInteractive().on('pointerup', () => {
      this.gameState = screenState.TABLE;
      this.getNextScreen();
    });

    this.add.text(515, 520, t('raw'), {fill: '#000000'});
    this.add.image(545, 572, 'rawdata').setInteractive().on('pointerup', () => {
      this.gameState = screenState.RAWDATA;
      this.getNextScreen();
    });
  }

  displayScreen(): void {
    switch (this.gameState) {
      case screenState.PLAYING:
        this.playingScreen();
        break;
      case screenState.TUTORIAL_ENDED:
        this.tutorialEnded();
        break;
      case screenState.ONHOLD:
        this.onHold();
        break;
      case screenState.GRAPH:
        this.graph();
        break;
      case screenState.TABLE:
        this.table();
        break;
      case screenState.RAWDATA:
        this.rawData();
        break;
      case screenState.EXPORT:
        this.exportCsv();
        break;
    }
  }

  getNextScreen(): void {
    this.destroyElements();

    if (this.clusterBlue === 0 && this.clusterOrange === 0 &&
      this.gameState === screenState.PLAYING && this.tutorialPlayed === false) {

      this.clusterBlue = 20;
      this.clusterOrange = 20;
      this.clusterLeft = 20;
      this.clusterRight = 20;
      this.currentColor = null;
      this.currentSide = null;
      this.tutorialPlayed = true;
      this.gameState = screenState.TUTORIAL_ENDED;
      this.results = [];
      return this.displayScreen();
    }

    if (this.clusterBlue === 0 && this.clusterOrange === 0 && this.gameState === screenState.PLAYING) {
      this.gameState = screenState.GRAPH;
      this.createEndFooterMenu();
      this.calculateStatistics();
    }

    this.displayScreen();
  }

  destroyPopup() {
    this.initialPopupTitle.destroy();
    this.initialPopupText.destroy();
  }

  playingScreen() {
    const sumOfColors = this.clusterOrange + this.clusterBlue;
    const sumOfSides = this.clusterLeft + this.clusterRight;
    const randomNumberColor = this.getRandomNumberBetween(1, sumOfColors);
    const randomNumberSide = this.getRandomNumberBetween(1, sumOfSides);

    this.destroyPopup();

    let color = null;
    let x = 100;

    if (randomNumberColor <= this.clusterBlue) {
      this.currentColor = colors.BLUE;
      color = 0x1b26f8;
    }

    if (randomNumberColor > this.clusterBlue) {
      this.currentColor = colors.ORANGE;
      color = 0xff4717;
    }

    if (randomNumberSide <= this.clusterLeft) {
      this.currentSide = sides.LEFT;
      x = 100;
    }
    if (randomNumberSide > this.clusterLeft) {
      this.currentSide = sides.RIGHT;
      x = 700;
    }
    this.square = this.add.rectangle(x, 300, 50, 50, color);

    if (!this.focusLine) {
      this.focusLine = this.add.rectangle(395, 300, 20, 10, 0x000000);
    }

    this.timer = this.time.addEvent({
      loop: true
    });
    this.inputGiven = false;
  }

  onHold() {
    this.text = this.add.text(150, 200, t('timeout'), {color: '#000'}) as any;
  }

  continue() {
    if (this.gameState === screenState.WAITING) {
      this.gameState = screenState.PLAYING;
      this.getNextScreen();
    }

    if (this.gameState === screenState.ONHOLD) {
      this.gameState = screenState.PLAYING;
      this.waitForNextScreen();
    }

    if (this.gameState === screenState.TUTORIAL_ENDED) {
      this.gameState = screenState.PLAYING;
      this.waitForNextScreen();
    }
  }

  exportCsv() {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: false,
      showTitle: false,
      filename: 'Simon Effect Export',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
    };

    const data = [];
    let count = 1;
    data.push({
      nr: t('nr'),
      position: t('position'),
      time: t('reaction_time'),
      correct: t('correct_header')
    });

    _.forEach(this.results, (result: Result) => {
      data.push({
        nr: count,
        position: this.getTerm(result.color, result.side),
        time: result.time,
        correct: (result.correct) ? t('correct_input') : t('incorrect_input')
      });
      count++;
    });
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  graph() {
    this.chartStat = new Chart(this, 400, 250, 600, 500, ChartConfig.get());
    this.chartStat.setChartData(0, 0, this.statistics.congruentLeftAvg).updateChart();
    this.chartStat.setChartData(0, 1, this.statistics.congruentRightAvg).updateChart();
    this.chartStat.setChartData(0, 2, this.statistics.incongruentLeftAvg).updateChart();
    this.chartStat.setChartData(0, 3, this.statistics.incongruentRightAvg).updateChart();
    this.add.existing(this.chartStat);
  }

  table() {
    let html = '';
    html += '<div style="overflow-y:auto; height: 400pt;"><table style="width: 500pt">';
    html += '<thead><th style="text-align: left;">' + t('position') + '</th><th style="text-align: left;">' + t('reaction_time') + '</th></thead>';

    html += '<tr>';
    html += '<td>Congruent ' + t('left') + '</td>';
    html += '<td>' + this.statistics.congruentLeftAvg + '</td>';
    html += '</tr>';

    html += '<tr>';
    html += '<td>Congruent ' + t('right') + '</td>';
    html += '<td>' + this.statistics.congruentRightAvg + '</td>';
    html += '</tr>';

    html += '<tr>';
    html += '<td>Incongruent ' + t('left') + '</td>';
    html += '<td>' + this.statistics.incongruentLeftAvg + '</td>';
    html += '</tr>';

    html += '<tr>';
    html += '<td>Incongruent ' + t('right') + '</td>';
    html += '<td>' + this.statistics.incongruentRightAvg + '</td>';
    html += '</tr>';

    html += '</table>';

    html += '<div>Congruent ' + t('left') + ': ' + t('orange') + ' ' + t('left') + '</div>';
    html += '<div>Congruent ' + t('right') + ': ' + t('blue') + ' ' + t('right') + '</div>';
    html += '<div>Incongruent ' + t('left') + ': ' + t('blue') + ' ' + t('left') + '</div>';
    html += '<div>Incongruent ' + t('right') + ': ' + t('orange') + ' ' + t('right') + '</div>';

    html += '</div>';

    this.tableStat = this.add.dom(500, 400).createFromHTML(html);
  }

  rawData() {
    let html = '';
    html += '<div style="overflow-y:auto; height: 300pt;"><table style="width: 400pt">';
    html += '<thead>' +
      '<th style="text-align: left;">#</th>' +
      '<th style="text-align: left;">' + t('position') + '</th>' +
      '<th style="text-align: left;">' + t('reaction_time') + '</th>' +
      '<th style="text-align: left;">' + t('correct_header') + '</th>' +
      '</thead>';

    let count = 1;
    _.forEach(this.results, (result) => {
      const correctIncorrect = (result.correct) ? t('correct_input') : t('incorrect_input');
      html += '<tr>';
      html += '<td>' + count + '</td>';
      html += '<td>' + this.getTerm(result.color, result.side) + '</td>';
      html += '<td>' + result.time + '</td>';
      html += '<td>' + correctIncorrect + '</td>';
      html += '</tr>';
      count++;
    });

    html += '</table></div>';

    this.rawStat = this.add.dom(500, 250).createFromHTML(html);
  }

  getTerm(color: string, side: string) {
    if (color === colors.ORANGE && side === sides.LEFT) {
      return 'Congruent ' + t('left');
    }
    if (color === colors.BLUE && side === sides.RIGHT) {
      return 'Congruent ' + t('right');
    }
    if (color === colors.BLUE && side === sides.LEFT) {
      return 'Incongruent ' + t('left');
    }
    if (color === colors.ORANGE && side === sides.RIGHT) {
      return 'Incongruent ' + t('right');
    }
  }

  destroyElements() {
    if (this.chartStat) {
      this.chartStat.destroy();
    }
    if (this.rawStat) {
      this.rawStat.destroy();
    }
    if (this.tableStat) {
      this.tableStat.destroy();
    }
    if (this.square) {
      this.square.destroy();
    }
    if (this.text) {
      this.text.destroy();
    }
    if (this.focusLine && this.clusterBlue === 0 && this.clusterOrange === 0 && this.gameState === screenState.PLAYING
      || this.focusLine && this.gameState === screenState.TUTORIAL_ENDED || this.focusLine && this.gameState === screenState.ONHOLD
    ) {
      this.focusLine.destroy();
      this.focusLine = null;
    }
  }

  press(color: string) {
    if (this.inputGiven || this.gameState !== screenState.PLAYING) {
      return;
    }
    const elapsed = this.timer.getElapsed();
    this.inputGiven = true;

    if (elapsed > 5000 && this.tutorialPlayed) {
      this.gameState = screenState.ONHOLD;
      return this.waitForNextScreen();
    }

    if (this.currentColor !== color) {
      this.text = this.add.text(350, 200, t('incorrect'), {color: '#000'}) as any;

      this.results.push({
        side: this.currentSide,
        color,
        time: this.roundDecimals(elapsed),
        correct: false
      });

      return this.waitForNextScreen();
    }

    this.text = this.add.text(365, 200, t('correct'), {color: '#000'}) as any;

    if (color === colors.BLUE) {
      this.clusterBlue--;
    }
    if (color === colors.ORANGE) {
      this.clusterOrange--;
    }

    if (this.currentSide === sides.LEFT) {
      this.clusterLeft--;
    }
    if (this.currentSide === sides.RIGHT) {
      this.clusterRight--;
    }

    this.results.push({
      side: this.currentSide,
      color,
      time: this.roundDecimals(elapsed),
      correct: true
    });

    return this.waitForNextScreen();
  }

  waitForNextScreen() {
    setTimeout(() => {
      this.emptyScreen();
    }, 1000);
  }

  emptyScreen() {
    this.destroyElements();

    setTimeout(() => {
      this.getNextScreen();
    }, 500);
  }

  calcAvg(side: string, color: string) {
    const dataSet = _.filter(this.results, (result: Result) => {
      return result.side === side && result.color === color && result.correct === true;
    });

    return this.roundDecimals(_.meanBy(dataSet, (result) => result.time));
  }

  calculateStatistics() {
    this.statistics = {
      congruentLeftAvg: this.calcAvg(sides.LEFT, colors.ORANGE),
      congruentRightAvg: this.calcAvg(sides.RIGHT, colors.BLUE),
      incongruentLeftAvg: this.calcAvg(sides.LEFT, colors.BLUE),
      incongruentRightAvg: this.calcAvg(sides.RIGHT, colors.ORANGE),
    };
  }
}
