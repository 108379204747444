import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import {t} from '../../i18n/i18n';

export class ChartConfig {

  static get() {

    return {
      plugins: {
        scene: [{
          key: 'rexUI',
          plugin: UIPlugin,
          mapping: 'rexUI'
        }]
      },
      type: 'bar',
      data: {
        labels: ['4', '16', '64', '4', '16', '64', '4', '16', '64', '4', '16', '64'],
        datasets: [{
          label: t('milliseconds'),
          backgroundColor: ['#76a6ab', '#76a6ab', '#76a6ab', '#7688ab', '#7688ab', '#7688ab', '#ab769c', '#ab769c', '#ab769c', '#c2596e', '#c2596e', '#c2596e'],
          borderColor: '118,166,171,1',
          borderWidth: 1,
          data: []
        }]
      },
      options: {
        responsive: true,
        legend: {
          display: false
        },
        title: {
          display: true,
          text: 'Data'
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: t('average_time_milliseconds')
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    };
  }
}

