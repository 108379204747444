export enum tiles {
  FOUR = 'four',
  SIXTEEN = 'sixteen',
  SIXTYFOUR = 'sixtyfour',
}
export enum stimulus {
  PRESENT = 'present',
  ABSENT = 'absent',
}

export enum types {
  FEATURE = 'feature',
  CONJUNCTIVE = 'conjunctive',
}

export enum screenState {
  WAITING = 'waiting',
  PLAYING = 'playing',
  ONHOLD = 'on-hold',
  TUTORIAL_ENDED = 'tutorial_ended',
  NEXT_GAME = 'next_game',
  GRAPH = 'graph',
  TABLE = 'table',
  RAWDATA = 'rawdata',
  EXPORT = 'export',
}