import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {SimonEffectComponent} from './pages/simon-effect/simon-effect.component';
import {VisualSearchComponent} from './pages/visual-search/visual-search.component';
import {PonzoIllusionComponent} from './pages/ponzo-illusion/ponzo-illusion.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'simon-effect/:lang', component: SimonEffectComponent },
  { path: 'visual-search/:lang', component: VisualSearchComponent },
  { path: 'ponzo-illusion/:lang', component: PonzoIllusionComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
