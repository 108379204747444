import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import {t} from '../../i18n/i18n';

export class ChartConfig {

  static get() {

    return {
      plugins: {
        scene: [{
          key: 'rexUI',
          plugin: UIPlugin,
          mapping: 'rexUI'
        }]
      },
      type: 'bar',
      data: {
        labels: ['Congruent ' + t('left'), 'Congruent ' + t('right'), 'Incongruent ' + t('left'), 'Incongruent ' + t('right')],
        datasets: [{
          label: t('milliseconds'),
          backgroundColor: ['#ffffff', '#ffffff', '#b3b3b3', '#b3b3b3'],
          borderColor: '#000000',
          borderWidth: 1,
          data: []
        }]
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'Data'
        },
        scales: {
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: t('average_time_milliseconds')
            },
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    };
  }
}
