import Phaser from 'phaser';

export class BaseScene extends Phaser.Scene {

  readonly defaultFontSize = '14px';
  readonly defaultHeaderFontSize = '16px';

  preload() {
    this.load.script('chartjs', 'https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.7.2/Chart.min.js');
    this.load.image('play', 'assets/icons/play-button.png');
    this.load.image('next', 'assets/icons/next.png');
    this.load.image('less', 'assets/icons/less.png');
    this.load.image('check', 'assets/icons/check.png');
    this.load.image('longer', 'assets/icons/longer.png');
    this.load.image('more', 'assets/icons/more.png');
    this.load.image('refresh', 'assets/icons/refresh.png');
    this.load.image('shorter', 'assets/icons/shorter.png');
    this.load.image('print', 'assets/icons/print.png');
    this.load.image('graph', 'assets/icons/graph.png');
    this.load.image('download', 'assets/icons/download.png');
    this.load.image('table', 'assets/icons/table.png');
    this.load.image('finish', 'assets/icons/finish.png');
    this.load.image('rawdata', 'assets/icons/raw.png');
  }

  roundDecimals(num: number) {
    return Math.round((num + Number.EPSILON));
  }

  getRandomNumberBetween(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
