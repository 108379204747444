import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SimonEffectComponent } from './pages/simon-effect/simon-effect.component';
import { SimonEffectBaseComponent } from './components/simon-effect-base/simon-effect-base.component';
import { VisualSearchComponent } from './pages/visual-search/visual-search.component';
import { VisualSearchBaseComponent } from './components/visual-search-base/visual-search-base.component';
import { PonzoIllusionComponent } from './pages/ponzo-illusion/ponzo-illusion.component';
import { PonzoIllusionBaseComponent } from './components/ponzo-illusion-base/ponzo-illusion-base.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SimonEffectComponent,
    SimonEffectBaseComponent,
    VisualSearchComponent,
    VisualSearchBaseComponent,
    PonzoIllusionComponent,
    PonzoIllusionBaseComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
