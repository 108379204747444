export enum colors {
  BLUE = 'blue',
  ORANGE = 'orange',
}

export enum sides {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum screenState {
  WAITING = 'waiting',
  PLAYING = 'playing',
  ONHOLD = 'on-hold',
  TUTORIAL_ENDED = 'tutorial_ended',
  GRAPH = 'graph',
  TABLE = 'table',
  RAWDATA = 'rawdata',
  EXPORT = 'export',
}
