<div class="container">
    <nav class="navbar navbar-light bg-light">
        <span class="navbar-brand mb-0 h1">ZAP Game dashboard</span>
    </nav>
    <div class="container mt-5">
        <div class="row">
            <div class="col">
                <div class="card">
                    <img class="card-img-top" src="/assets/samples/simon-effect.png" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">Simon effect</h5>
                        <p class="card-text">Het is de bedoeling, en erg belangrijk, dat je jouw ogen gericht houdt op
                            het
                            fixatiepunt. Vervolgens probeer je zo snel mogelijk correct te reageren op het blauwe of
                            oranje
                            blokje
                            dat zal verschijnen.</p>

                        <a [routerLink]="['/simon-effect', 'en']" class="btn btn-primary mr-1">English</a>
                        <a [routerLink]="['/simon-effect', 'nl']" class="btn btn-primary">Nederlands</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <img class="card-img-top" src="/assets/samples/visual-search.png" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">Visual search</h5>
                        <p class="card-text">Probeer zo snel mogelijk de groene cirkel te vinden tussen de andere
                            figuren.
                        </p>

                        <a [routerLink]="['/visual-search', 'en']" class="btn btn-primary mr-1">English</a>
                        <a [routerLink]="['/visual-search', 'nl']" class="btn btn-primary">Nederlands</a>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card">
                    <img class="card-img-top" src="/assets/samples/ponzo-illusion.png" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">Ponzo illusion</h5>
                        <p class="card-text">Probeer door systematisch te variëren een beeld te krijgen van de werking
                            van
                            verschillende variabelen op de mate waarin de Ponzo illusie optreedt.</p>

                        <a [routerLink]="['/ponzo-illusion', 'en']" class="btn btn-primary mr-1">English</a>
                        <a [routerLink]="['/ponzo-illusion', 'nl']" class="btn btn-primary">Nederlands</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
